import axios from 'axios';

export const get = async (limit=10, earliestDate=null) => {

  const response = await axios.get('/api/comments',{ params: {limit, earliestDate} })

  if(response.status !== 200) { 
    console.error("Error getting comments", response);
    return [];
  }

  const comments = response.data.map(comment => ({...comment, date: new Date(comment.date)}))
  
  return comments
}

export const post = async(comment) => {
  try {
    await axios.post('/api/comments', comment)
  } catch(e) {
    console.error(e)
  }
}
