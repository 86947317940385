import React, { Component } from 'react';
import Modal from 'react-modal';
import CommentList from './CommentList';
import CommentForm from './CommentForm';
import style from './style';
import api from './services/api'

class CommentBox extends Component {
  constructor(props) {
    super(props);
    this.state = { data: [], isMoreComments: true, modalIsOpen: false };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  loadCommentsFromServer = async (limit=20) => {
    const comments = await api.comments.get(limit);
    this.setState({data: comments});
  }

  loadMoreCommentsFromServer = async (limit=10) => {
    const earliestDate = this.state.data.slice(-1)[0].date;
    const comments = await api.comments.get(limit, earliestDate);

    if (comments.length === 0) this.setState({ isMoreComments: false });

    this.setState(prevState => ({ data: prevState.data.concat(comments) }));
  }

  handleCommentSubmit = async (comment) => {
    await api.comments.post(comment)
    this.closeModal();
    this.loadCommentsFromServer();
  }

  componentDidMount() {
    this.loadCommentsFromServer();
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }
  
  render() {
    return (
      <div style={ style.commentBox }>
      <button style={ style.commentButton } onClick={this.openModal}>Leave Message</button>
      <CommentList data={ this.state.data } fetchFunction={  this.loadMoreCommentsFromServer } isMoreComments={ this.state.isMoreComments }/>
      <Modal
        isOpen={this.state.modalIsOpen}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={this.closeModal}
        style={style.commentFormModal}
        contentLabel="Leave a comment"
      >
        <button onClick={this.closeModal} className='close' style={style.modalButton}> 
        <span>&times;</span>
        </button>
        <CommentForm onCommentSubmit={ this.handleCommentSubmit }/>
      </Modal>
      </div>
    )
  }
}

export default CommentBox;
